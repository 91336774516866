<template>
  <div class="clickspex-filters">
    <div class="clickspex-filters__header">
      <span>Selected filters: {{ filtersLength }}</span>
      <button class="btn" @click="resetAllFilters()">Reset filters</button>
      <button class="clickspex-filters__close" @click="$emit('closeFilters')">
        Close
      </button>
    </div>
    <div class="clickspex-filters__content">
      <div class="clickspex-filters__check-box-content">
        <div class="custom-checkbox">
          <input
            type="checkbox"
            value="Male"
            v-model="checkboxFilters.gender"
            id="male"
            @change="setCommentsFilter()"
          />
          <label for="male">Males</label>
        </div>
        <div class="custom-checkbox">
          <input
            type="checkbox"
            value="Female"
            v-model="checkboxFilters.gender"
            id="female"
            @change="setCommentsFilter()"
          />
          <label for="female">Females</label>
        </div>
        <div class="custom-checkbox">
          <input
            type="checkbox"
            value="[18, 24]"
            v-model="checkboxFilters.yrs"
            id="yrs1"
            @change="setCommentsFilter()"
          />
          <label for="yrs1">18-24 yrs</label>
        </div>
        <div class="custom-checkbox">
          <input
            type="checkbox"
            value="[25, 34]"
            v-model="checkboxFilters.yrs"
            id="yrs2"
            @change="setCommentsFilter()"
          />
          <label for="yrs2">25-34 yrs</label>
        </div>
        <div class="custom-checkbox">
          <input
            type="checkbox"
            value="[35, 49]"
            v-model="checkboxFilters.yrs"
            id="yrs3"
            @change="setCommentsFilter()"
          />
          <label for="yrs3">35-49 yrs</label>
        </div>
        <div class="custom-checkbox">
          <input
            type="checkbox"
            value="[50, 59]"
            v-model="checkboxFilters.yrs"
            id="yrs4"
            @change="setCommentsFilter()"
          />
          <label for="yrs4">50-59 yrs</label>
        </div>
        <div class="custom-checkbox">
          <input
            type="checkbox"
            value="[60, 1000]"
            v-model="checkboxFilters.yrs"
            id="yrs5"
            @change="setCommentsFilter()"
          />
          <label for="yrs5">60+ yrs</label>
        </div>
      </div>

      <div class="clickspex-filters__select-content">
        <select name="" id="" @change="setCommentsFilter()" v-model="iconID">
          <option>All clicks</option>
          <option>Clicks with comment</option>
          <option value="1">Like</option>
          <option value="2">Dislike</option>
        </select>
        <select
          name=""
          id=""
          @change="setCommentsFilter()"
          v-model="clickOrder"
        >
          <option value="All clicks">All clicks</option>
          <option
            v-for="item in getUniqeClickOrder"
            :key="item.clickOrder"
            :value="item.clickOrder"
          >
            {{ item.clickOrder }}
          </option>
        </select>
      </div>

      <div class="clickspex-filters__comments">
        <ul>
          <li
            v-for="(item, index) in getFilteredComments"
            :key="index"
            @click="activeComment(index, !item.isActive)"
            :class="{
              'green-border': item.iconID === 1 && item.isActive,
              'red-border': item.iconID === 2 && item.isActive,
            }"
          >
            <span v-if="item.comment !== ''">{{ item.comment }}</span>
            <span v-else>&#60;no comment provided&#62;</span>
          </li>
        </ul>
      </div>

      <div class="flex justify-end clickspex-filters__export">
        <button class="btn" @click="exportReport">Export</button>
      </div>
    </div>
  </div>
</template>

<script>
import { store, mutations } from "@/utils/store.js";

export default {
  name: "Filters",
  components: {},
  data() {
    return {
      iconID: "All clicks",
      clickOrder: "All clicks",
      checkboxFilters: {
        gender: [],
        yrs: [],
      },
      filtersLength: 0,
    };
  },
  props: {
    activeFilter: {
      type: String,
    },
  },
  watch: {
    activeFilter(newVal) {
      this.resetAllFilters();
      if (newVal === "Female" || newVal === "Male") {
        this.checkboxFilters.gender.push(newVal);
      } else if (newVal !== "Total") {
        let yrs;
        if (newVal === "60") {
          yrs = `[60, 1000]`;
          this.checkboxFilters.yrs.push(yrs);
        } else {
          yrs = newVal.split("-").join(", ");
          this.checkboxFilters.yrs.push(`[${yrs}]`);
        }
      }
      setTimeout(() => {
        this.setCommentsFilter();
      }, 200);
    },
  },
  methods: {
    setCommentsFilter() {
      const filters = {
        iconID: this.iconID !== "All clicks",
        clickOrder: this.clickOrder !== "All clicks",
      };
      const activeFilters = Object.keys(filters).filter(
        (k) => filters[k] === true
      );
      this.filtersLength =
        activeFilters.length +
        this.checkboxFilters.gender.length +
        this.checkboxFilters.yrs.length;
      this.$emit("filtersLength", this.filtersLength);

      let yrs = [];
      this.checkboxFilters.yrs.forEach((item) => {
        yrs.push(JSON.parse(item));
      });

      mutations.commentsLikeDislikeFilter({
        iconID: this.iconID,
        clickOrder: this.clickOrder,
        gender:
          this.checkboxFilters.gender.length === 0
            ? ["Male", "Female"]
            : this.checkboxFilters.gender,
        yrs:
          yrs.length !== 0
            ? yrs
            : [
                [18, 24],
                [25, 34],
                [35, 49],
                [50, 59],
                [60, 1000],
              ],
      });
    },
    activeComment(index, isActive) {
      mutations.setActiveComment({ index: index, isActive: isActive });
    },
    resetAllFilters() {
      this.iconID = "All clicks";
      this.clickOrder = "All clicks";
      this.checkboxFilters.gender = [];
      this.checkboxFilters.yrs = [];
      this.filtersLength = 0;
      mutations.resetClickspexFilters();
    },
    exportReport() {
      let apiUrl;
      if (
        window.location.hostname === "app.hotspexlabs.com" ||
        window.location.hostname === "pg.hotspexlabs.com"
      ) {
        apiUrl = "https://admin.hotspexlabs.com/";
      } else if (window.location.hostname === "app-uat.hotspexstudio.com") {
        apiUrl = "https://admin-uat.hotspexstudio.com";
      } else {
        apiUrl = "https://staging-admin.hotspexstudio.com";
      }
      let surveyId = encodeURIComponent(this.$route.query.SurveyCellID);
      let yrs = this.checkboxFilters.yrs
        .toString()
        .split("[")
        .join("")
        .split("]")
        .join("")
        .split(", ")
        .join("-")
        .split("60-1000")
        .join("60+");
      if (yrs.length === 0) {
        yrs = "All";
      }

      let likeDislike = "All";
      let comments = false;
      switch (this.iconID) {
        case "All clicks":
          likeDislike = "All";
          break;
        case "Clicks with comment":
          comments = true;
          break;
        case "1":
          likeDislike = "Like";
          break;
        case "2":
          likeDislike = "Dislike";
          break;
        default:
          break;
      }

      let gender =
        this.checkboxFilters.gender.length === 1
          ? this.checkboxFilters.gender
          : "All";

      let clicks = this.clickOrder === "All clicks" ? "All" : this.clickOrder;

      window.open(
        `${apiUrl}/Reports/ClickSpexCSVData?a=${surveyId}&b=${gender}&c=${yrs}&d=${likeDislike}&e=${clicks}&f=${comments}`,
        "_blank"
      );
    },
  },
  computed: {
    getFilteredComments() {
      return store.clickspexReport.filteredComments;
    },
    getUniqeClickOrder() {
      var resArr = [];
      store.clickspexReport.comments.filter(function (item) {
        var i = resArr.findIndex((x) => x.clickOrder == item.clickOrder);
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
      return resArr;
    },
  },
};
</script>

<style lang="scss">
.clickspex-filters {
  background-color: #2f2f30;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 800px) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  & * {
    color: #fff;
  }
  .btn {
    font-size: 14px;
    padding: 6px 10px;
    background: #5bbfde;
    border-color: #5bbfde;
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    &:hover {
      background: #51afcc;
      border-color: #51afcc;
    }
    &--disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
  .custom-checkbox {
    margin-bottom: 6px;
    label {
      position: relative;
      padding-left: 20px;
      user-select: none;
      cursor: pointer;
      font-size: 14px;
      display: block;
      &:hover {
        &:after {
          border: 2px solid #fff;
        }
      }
      &:after,
      &::before {
        position: absolute;
        display: block;
        content: "";
      }
      &:after {
        left: 0;
        top: 2px;
        width: 15px;
        height: 15px;
        border: 1px solid #fff;
        border-radius: 2px;
      }
      &:before {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='405.272' height='405.272' style='enable-background:new 0 0 405.272 405.272' xml:space='preserve'%3E%3Cpath fill='%23fff' d='M393.401 124.425 179.603 338.208c-15.832 15.835-41.514 15.835-57.361 0L11.878 227.836c-15.838-15.835-15.838-41.52 0-57.358 15.841-15.841 41.521-15.841 57.355-.006l81.698 81.699L336.037 67.064c15.841-15.841 41.523-15.829 57.358 0 15.835 15.838 15.835 41.514.006 57.361z'/%3E%3C/svg%3E");
        background-size: contain;
        left: 3px;
        top: 4px;
        z-index: 1;
        width: 10px;
        height: 10px;
        transform: rotate(-9deg);
        display: none;
      }
    }
    input {
      display: none;
      &:checked {
        + label {
          font-weight: 600;
          &:after {
            background: #51afcc;
            border-color: #51afcc;
          }
          &:before {
            display: block;
          }
        }
      }
    }
  }
  &__close {
    display: none;
    background: transparent;
    border: 0;
    margin-left: 12px;
    cursor: pointer;
    @media only screen and (max-width: 800px) {
      display: block;
    }
  }
  &__header {
    font-size: 14px;
    padding: 12px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 800px) {
      justify-content: flex-start;
      > span {
        flex: 1;
      }
    }
  }
  &__content {
    padding: 0 15px;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
  }
  &__check-box-content {
    border: 1px solid #484747;
    border-radius: 4px;
    padding: 12px 10px;
    background: #3c3c3c;
    margin-bottom: 15px;
    .custom-checkbox {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__select-content {
    margin-bottom: 15px;
    background: #3c3c3c;
    display: flex;
    justify-content: space-between;
    select {
      color: #2f2f30;
      width: calc(50% - 7px);
      font-size: 14px;
      height: 28px;
      border-radius: 4px;
      cursor: pointer;
      option {
        color: #2f2f30;
      }
    }
  }
  &__comments {
    border: 1px solid #484747;
    border-radius: 4px;
    background: #3c3c3c;
    flex: 1;
    position: relative;
    height: calc(100% - 324px);
    margin-bottom: 10px;
    @media only screen and (max-width: 800px) {
      height: calc(100% - 285px);
    }
    &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: "";
      height: 50px;
      width: 100%;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(47, 47, 48, 0.85) 100%
      );
      pointer-events: none;
    }
    ul {
      position: absolute;
      inset: 0;
      overflow-y: auto;
      padding: 0;
      li {
        border-bottom: 1px solid #646464;
        padding: 10px;
        font-size: 14px;
        border-width: 0 0 1px 4px;
        border-color: transparent transparent #646464 transparent;
        border-style: solid;
        cursor: pointer;
        transition: all 0.2s ease;
        &:hover {
          border-left-color: #fff;
        }
        &.green-border {
          border-left-color: #40d802;
        }
        &.red-border {
          border-left-color: #f50804;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>
