import axios from "axios";

let apiUrl;
if (window.location.hostname === "app.hotspexlabs.com" ||
window.location.hostname === "pg.hotspexlabs.com") {
  apiUrl = "https://admin.hotspexlabs.com";
} else if (window.location.hostname === "app-uat.hotspexstudio.com") {
  apiUrl = "https://admin-uat.hotspexstudio.com";
} else {
  apiUrl = "https://staging-admin.hotspexstudio.com";
}

let wsStudioHeader = `${apiUrl}/WebService.asmx/`;
let apiStudio = axios.create({
  baseURL: wsStudioHeader,
  headers: {
    Accept: "application/json",
    "content-type": "application/json",
  },
});

export function getComments(data, callback) {
  apiStudio.post("clickspexCommentByFilter", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data.comments);
      }
    },
    (error) => {
      console.log("getComments fail: " + error);
    }
  );
}

export function getClickspexUnderCip(data, callback) {
  apiStudio.post("getClickspexUnderCip", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data.clickspexs[0]);
      }
    },
    (error) => {
      console.log("getClickspexUnderCip fail: " + error);
    }
  );
}
