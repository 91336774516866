<template>
  <div>
    <div class="report">
      <div class="report__filters-trigger" @click="showFilters = true">
        Filters ({{ activeFiltersLength }}) & Comments
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 25.451 25.451"
          style="enable-background: new 0 0 25.451 25.451"
          xml:space="preserve"
        >
          <path
            d="M20.982.521v2.006L8.57 12.315c-.121.101-.195.251-.195.41s.074.311.195.41l12.412 9.79v2.005a.52.52 0 0 1-.297.469.519.519 0 0 1-.549-.061L4.664 13.136a.531.531 0 0 1 0-.82L20.136.113a.523.523 0 0 1 .846.408z"
          />
        </svg>
      </div>
      <div class="report__left-col" :class="{ 'is-active': showFilters }">
        <Filters
          @filtersLength="filtersLength"
          @closeFilters="showFilters = false"
          :activeFilter="activeFilter"
        ></Filters>
      </div>
      <div class="report__right-col">
        <Report></Report>
      </div>
    </div>
  </div>
</template>

<script>
import * as wsUtilsClickspexReport from "@/utils/wsUtilsClickspexReport.js";
import Filters from "../components/clickspexReport/Filters.vue";
import Report from "../components/clickspexReport/Report.vue";
import { mutations } from "@/utils/store.js";
export default {
  name: "ClickspexReport",
  components: { Filters, Report },
  props: {},
  data() {
    return {
      showFilters: false,
      activeFiltersLength: 0,
      activeFilter: null,
    };
  },
  methods: {
    getAllComments(data) {
      let query = this.$route.query;
      const commentsList = new Promise((resolve) => {
        wsUtilsClickspexReport.getComments(data, resolve);
      });

      commentsList.then((data) => {
        mutations.setClickspexReportComments(data);
        this.activeFilter = query.Filter;
      });
    },
    getImage(data) {
      const image = new Promise((resolve) => {
        wsUtilsClickspexReport.getClickspexUnderCip(data, resolve);
      });

      image.then((data) => {
        mutations.setClickspexReportImage(data);
      });
    },
    filtersLength(e) {
      this.activeFiltersLength = e;
    },
  },
  activated() {
    let query = this.$route.query;
    this.getAllComments({
      SurveyCellID: query.SurveyCellID,
      filter: "Total",
      needTranslate: query.needTranslate,
    });
    this.getImage({
      SurveyCellID: query.SurveyCellID,
    });
  },
};
</script>

<style lang="scss" scoped>
.report {
  text-align: left;
  display: flex;
  height: calc(100vh - 55px);
  @media only screen and (max-width: 800px) {
    display: block;
    padding: 20px 0;
  }
  &__left-col {
    width: 350px;
    @media screen and (max-width: 1100px) {
      width: 250px;
    }
    @media only screen and (max-width: 800px) {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      padding: 55px 0 0;
      overflow: auto;
      display: none;
      &.is-active {
        display: block;
      }
    }
  }
  &__filters-trigger {
    text-align: center;
    border-width: 1px 0;
    border-style: solid;
    font-size: 14px;
    border-color: #edecec;
    font-weight: 600;
    color: #007185;
    padding: 9px;
    margin-bottom: 20px;
    cursor: pointer;
    display: none;
    @media only screen and (max-width: 800px) {
      display: block;
    }
    svg {
      width: 7px;
      stroke: 2px;
      stroke-width: 3px;
      stroke: #007185;
      transform: rotate(-90deg);
    }
  }
  &__right-col {
    flex: 1;
    padding: 12px 15px;
    overflow-y: auto;
  }
}
</style>
