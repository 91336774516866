<template>
  <div>
    <div class="report-details" v-if="getImageData !== ''">
      <img
        ref="reportImage"
        :src="getImageData.imageURL"
        :alt="getImageData.imageName"
      />

      <p
        class="report-details__dots-loader flex items-center"
        v-if="dotsLoader"
      >
        <i class="circle-loader"></i>Loading data...
      </p>

      <div class="report-details__dots" v-if="imageWidth !== 0">
        <div
          class="report-details__dots-item"
          v-for="(item, index) in getFilteredComments"
          :key="index"
          :style="{
            left: (item.xPos / imageWidth) * 100 + '%',
            top: (item.yPos / imageHeight) * 100 + '%',
          }"
          :class="{
            'is-active': item.isActive,
          }"
        >
          <span
            @click.self="activeComment(index, !item.isActive)"
            :class="{
              'green-bcg': item.iconID === 1,
              'red-bcg': item.iconID === 2,
            }"
            class="report-details__dots-item-circle"
          ></span>
          <div class="report-details__dots-tooltip">
            <span class="flex items-center"
              ><strong>spexID:</strong> {{ item.spexID }}
              <svg
                @click.self="activeComment(index, !item.isActive)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                style="enable-background: new 0 0 512 512"
                xml:space="preserve"
              >
                <path
                  d="m375.808 143.28-11.504-11.12-108.128 111.984L148.032 132.16l-11.488 11.12 108.512 112.384-108.512 112.368 11.488 11.12 108.144-111.984 108.128 111.984 11.504-11.12-108.512-112.368z"
                />
                <path
                  d="M0 0v512h512V0H0zm496.032 496.032H15.968V15.968h480.064v480.064z"
                /></svg
            ></span>
            <p v-if="item.comment !== ''">{{ item.comment }}</p>
            <p v-else>[ ]</p>
          </div>
        </div>
      </div>
    </div>
    <p v-else class="loader"><i class="circle-loader"></i>Loading data...</p>
  </div>
</template>

<script>
import { store, mutations } from "@/utils/store.js";

export default {
  name: "Report",
  components: {},
  data() {
    return {
      imageWidth: 0,
      imageHeight: 0,
    };
  },
  methods: {
    activeComment(index, isActive) {
      mutations.setActiveComment({ index: index, isActive: isActive });
    },
  },
  computed: {
    getImageData() {
      return store.clickspexReport.imageData;
    },
    getFilteredComments() {
      return store.clickspexReport.filteredComments;
    },
    dotsLoader() {
      return this.imageWidth === 0;
    },
  },
  watch: {
    getImageData: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (this.$refs.reportImage) {
            setTimeout(() => {
              this.imageWidth = this.$refs.reportImage.naturalWidth;
              this.imageHeight = this.$refs.reportImage.naturalHeight;
            }, 1500);
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    //works! child components were loaded
  },
};
</script>

<style lang="scss">
.report-details {
  border: 2px solid #000;
  display: inline-flex;
  position: relative;
  user-select: none;
  > img {
    width: 100%;
  }
  &__dots-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 3px;
    padding: 5px 9px;
  }
  &__dots {
    position: absolute;
    inset: 0;
  }
  &__dots-item {
    position: absolute;
    width: 15px;
    height: 15px;
    cursor: pointer;
    transition: outline-color 0.2s ease;
    &.is-active {
      outline-color: #fff;
      z-index: 1;
      .report-details__dots-tooltip {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
      }
    }
    &:hover {
      z-index: 2 !important;
      outline-color: #fff;
    }
  }
  &__dots-item-circle {
    border-radius: 50%;
    border: 2px solid #fff;
    background: #000;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
    outline: 1px solid transparent;
    display: block;
    width: 15px;
    height: 15px;
    &.red-bcg {
      background: #f50804;
      .report-details__dots-tooltip {
        background: #f5ebeb;
        &:after {
          border-color: transparent #f5ebeb transparent transparent;
        }
      }
    }
    &.green-bcg {
      background: #40d802;
      .report-details__dots-tooltip {
        background: #d0ffe0;
        &:after {
          border-color: transparent #d0ffe0 transparent transparent;
        }
      }
    }
    &:hover {
      ~ .report-details__dots-tooltip {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
      }
    }
  }
  &__dots-tooltip {
    background: #fff;
    position: absolute;
    left: 100%;
    top: -9px;
    margin-left: 10px;
    width: 170px;
    padding: 5px 5px 6px;
    border-radius: 4px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateX(5px);
    transition: all 0.3s ease;
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 8px 0;
      border-color: transparent #fff transparent transparent;
      left: -8px;
      top: 6px;
    }
    span {
      font-size: 14px;
      border-bottom: 1px solid #878383;
      padding-bottom: 5px;
      margin-bottom: 5px;
      strong {
        padding-right: 3px;
      }
    }
    p {
      font-size: 14px;
      line-height: 18px;
    }
    svg {
      width: 15px;
      height: 15px;
      margin-left: auto;
    }
  }
}

.loader {
  height: calc(100vh - 130px);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
