import axios from "axios";

let apiUrl;
if (window.location.hostname === "app.hotspexlabs.com" ||
window.location.hostname === "pg.hotspexlabs.com") {
  apiUrl = "https://admin.hotspexlabs.com";
} else if (window.location.hostname === "app-uat.hotspexstudio.com") {
  apiUrl = "https://admin-uat.hotspexstudio.com";
} else {
  apiUrl = "https://staging-admin.hotspexstudio.com";
}

let wsStudioHeader = `${apiUrl}/WebService.asmx/`;
let apiStudio = axios.create({
  baseURL: wsStudioHeader,
  headers: {
    Accept: "application/json",
    "content-type": "application/json",
  },
});

export function getMapExplorerImage(data, callback) {
  apiStudio.post("GetPersonaImageUrl", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("RegisterUser fail: " + error);
    }
  );
}

export function getMapExplorerFilterList(data, callback) {
  apiStudio.post("GetPersonaMFilterList", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("RegisterUser fail: " + error);
    }
  );
}

export function getMapExplorerValues(data, callback) {
  apiStudio.post("GetPersonaMValues", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("RegisterUser fail: " + error);
    }
  );
}
