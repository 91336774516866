<template>
  <div class="error-page flex justify-center items-center">
    <div class="error-page__info">
      <h1>Something went wrong!</h1>
      <img src="/images/404.png" alt="Something went wrong!" />
      <p>
        Oh no! This wasn't supposed to happen, the page you were looking for may
        no longer exist.
      </p>
    </div>
    <div class="error-page__footer flex">
      <p>© 2022 Hotspex Labs</p>
      <ul class="flex">
        <li><a href="">Terms and conditions</a></li>
        <li><a href="">Contacts</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
  data() {
    return {};
  },

  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.error-page {
  background: #e4edf2;
  flex-direction: column;
  padding: 50px 30px;
  &__info {
    max-width: 600px;
    margin: 0 auto;
    h1 {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 46px;
      margin-bottom: 30px;
    }
    img {
      width: 100%;
      margin-bottom: 4px;
    }
    p {
      font-size: 26px;
      font-weight: 300;
      color: #000;
    }
  }
  &__footer {
    width: 100%;
    max-width: 1190px;
    justify-content: space-between;
    padding-top: 40px;
    * {
      color: var(--lightText);
    }
    ul {
      li {
        margin: 0 11px;
        position: relative;
        &::after {
          position: absolute;
          right: -11px;
          top: 50%;
          transform: translateY(-50%);
          height: 16px;
          border-right: 1px solid var(--lightText);
          content: "";
        }
        &:last-child {
          margin-right: 0;
          &:after {
            display: none;
          }
        }
        a {
          &:hover {
            color: var(--hsgrey);
          }
        }
      }
    }
  }
  &__loading {
    height: 544px;
  }
}
</style>
